import React from "react";
import styled, { keyframes } from "styled-components";

/* Styled Components 
---------------------------------------------*/

const blinkAnimation = keyframes`
  0% {
    animation-timing-function: ease-out;
    // transform: translateZ(100%);
    transform: rotate3d(0, 1, 0, -90deg);
    transform-origin: center center;
  }

  45% {
    animation-timing-function: ease-out;
    transform: rotate3d(0, 1, 0, 0deg);
  }
`
// const borderAnimation = keyframes`
//   from {
//     background-position: 0;
//   }

//   to {
//     background-position: 0 450px;
//   }
// `

const Card = styled.div`
  position: relative;
  border: 4px solid #fff;
  padding 0;
  border-radius: 6px;
  line-height: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const FlippedCard = styled(Card)`
  animation: ${blinkAnimation} 2s ease 0s 1 normal forwards;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: calc(33.33vh - 5em);
  border-radius: 4px;

  transition: all 0.2s ease-in-out;
  transform: scale(1);

  &:hover {
    border-color: yellow;
    transform: scale(1.08);
  }
`

/* Card Component 
------------------------------------------------*/

const SingleCard = ({ card, handleOnClick, flipped }) => {
  if (flipped) {
    return (
      <FlippedCard>
        <Image src={card.src} alt="card front" />
      </FlippedCard>
    );
  } else {
    return (
      <Card>
        <Image
          src="https://game.mrduckclub.com/images/memory-cards/unknown.png"
          alt="card back"
          onClick={() => handleOnClick(card)}
        />
      </Card>
    );
  }
};

export default SingleCard;
