import { useState, useEffect } from 'react';
import CardGrid from './components/CardGrid';
import styled from 'styled-components'

/* Styled Components 
---------------------------------------------*/

const GameBoard = styled.div`
  text-align: center;
  display:grid;
  justify-items: center;
  width: 100%;
`

const NewButton = styled.button`
  font-family: Roboto Mono, monospace;
  padding: 1em; 
  border: 2px solid white;
  border-radius: 8px;
  color: rgb(22, 22, 24);
  font-weight: 700;
  font-size: 16px;
  background-color: #fff;
  cursor: pointer;
`

const Paragraph = styled.p`
  font-weight: 700;
  font-size: 2em;
  margin-bottom: 0;
`

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const generateUniqueRandomNumbers = (count, min, max) => {
  if (count > (max - min + 1)) {
    throw new Error("Cannot generate more unique numbers than the available range.");
  }

  const uniqueNumbers = new Set();

  while (uniqueNumbers.size < count) {
    const randomNumber = getRandomInt(min, max);
    uniqueNumbers.add(randomNumber);
  }

  return Array.from(uniqueNumbers);
}


/* Card Images
---------------------------------------------- */

const getCardImages = () => {
  const randomNumbers = generateUniqueRandomNumbers(6, 1, 101);

  console.log(randomNumbers)
  
  const cardImages = [
    { src: `https://game.mrduckclub.com/images/memory-cards/${randomNumbers[0]}.png`, flipped: false },
    { src: `https://game.mrduckclub.com/images/memory-cards/${randomNumbers[1]}.png`, flipped: false },
    { src: `https://game.mrduckclub.com/images/memory-cards/${randomNumbers[2]}.png`, flipped: false },
    { src: `https://game.mrduckclub.com/images/memory-cards/${randomNumbers[3]}.png`, flipped: false },
    { src: `https://game.mrduckclub.com/images/memory-cards/${randomNumbers[4]}.png`, flipped: false },
    { src: `https://game.mrduckclub.com/images/memory-cards/${randomNumbers[5]}.png`, flipped: false }
  ]

  return cardImages
}

/* App Component 
------------------------------------------------*/

function App() {

  const [images, setImages] = useState([]);
  const [turns, setTurns] = useState(0);

  // Function to shuffle cards
  const shuffleCards = () => {
    const cardImages = getCardImages();

    const newCards = [...cardImages, ...cardImages]
      .sort(() => Math.random() - 0.5)
      .map((value, index) => { return {...value, index};});
    setImages(newCards);
    setTurns(0);
  }

  // Initialize board on page load
  useEffect(() => {
    shuffleCards()
  }, [])


  return (
    <GameBoard>
      <NewButton onClick={() => shuffleCards()}>New Game</NewButton>
      <CardGrid images={images} setImages={setImages} setTurns={setTurns} />
      <Paragraph>TURNS: {turns}</Paragraph>
    </GameBoard>
  );
}

export default App;
